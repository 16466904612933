import React, { Fragment, useState, useEffect } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../components/layout"
import Sidebar from "../components/sidebar"
import HeaderSimple from "../components/blocks/HeaderSimple";

import "../styles/posts-list.scss"

export default function SearchPage({ location }) {
  const data = useStaticQuery(graphql`
    query searchQuery {
      wpPage(slug: { eq: "news" }) {
        blocks {
          layouts {
            ... on WpPage_Blocks_Layouts_HeaderSimple {
              fieldGroupName
              title
              background {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              mobileBackground {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
      allWpPost(
        sort: { fields: [date], order: DESC }
      ) {
        nodes {
          uri
          date(formatString: "MMMM DD, YYYY")
          title
          excerpt
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `);

  const heading = data?.wpPage?.blocks?.layouts
  const posts = data?.allWpPost?.nodes
  const [search, setSearch] = useState('')
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    if (isBrowser) {
      const searchQuery = window.location.search.replace('?s=', '')
      setSearch(searchQuery)
    }

    // filter posts
    if (search) {
      document.querySelectorAll('.post').forEach(post => {
        const title = post.querySelector('.title')
        const excerpt = post.querySelector('.description')

        if (title.innerHTML.toLowerCase().includes(search.toLowerCase()) || excerpt.innerHTML.toLowerCase().includes(search.toLowerCase())) {
          post.style.display = 'block'
        } else {
          post.style.display = 'none'
        }
      })
    }
  }, [isBrowser, search])

  return (
    <Fragment>
      {/*<Seo
        title="News"
        // postSchema={JSON.parse(
        //   data.wp?.seo?.contentTypes?.post.schema.raw
        // )}
      />*/}
      <Layout location={location}>
        {heading && (
          <HeaderSimple data={heading[0]} />
        )}

        <div className="row news-row">
          <div className="search-bar">
            <form action="/search/">
              <input name="s" type="search" placeholder="search news..." />
              <button>
                <StaticImage src="../images/search-icon.png" />
              </button>
            </form>
          </div>

          <div className="posts-list-wrapper">
            {search && (
              <h1 className="title">Search: {search}</h1>
            )}
            {posts?.map(post => {
              return (
                <article className="post">
                  <div className="row">
                    <div className="col featured-image">
                      <Link to={post?.uri}>
                        <GatsbyImage image={post?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData} alt={post?.altText} />
                      </Link>
                    </div>
                    <div className="col post-content">
                      <h2 className="title"><Link to={post?.uri}>{post?.title}</Link></h2>
                      <div className="date">{post?.date}</div>
                      <div className="description" dangerouslySetInnerHTML={{ __html: post?.excerpt}}></div>
                    </div>
                  </div>
                </article>
              )
            })}
          </div>
          <div className="sidebar-wrapper">
            <Sidebar search={search} />
          </div>
        </div>
      </Layout>
    </Fragment>
  )
}
